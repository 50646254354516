body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
	'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
	sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
	monospace;
}

:root {
	--white-button-color: #FFFFFF;
	--white-color: #FFFFFF;
	--orange-text-color: #FF6A00;
	--orange-color: #F45E20;
	--orange-hover-color: #D43E00;
	--orange-active-color: #C03800;
	--white-text-color: #FFFFFF;
	--gray-text-color: #80868b;
	--gray-bg-color: #F7F7F7;
	--dark-color: #000C17;
	--dark-card-color: #0E1B27;
	--dark-text-color: #000C17;
}